import React from "react"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { Helmet } from "react-helmet"

const wrapRootElement = ({ element }) => {
  return (
    <React.Fragment>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
      </Helmet>
      {element}
    </React.Fragment>
  )
}

export { wrapRootElement }
